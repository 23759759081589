import gallery1 from "../assets/image/gallery1.jpg";
import gallery2 from "../assets/image/gallery2.jpg";
import gallery3 from "../assets/image/gallery3.jpg";
import gallery4 from "../assets/image/gallery4.jpeg";
import gallery5 from "../assets/image/gallery5.jpg";
import gallery6 from "../assets/image/gallery6.jpg";
import gallery7 from "../assets/image/gallery7.jpg";
import gallery8 from "../assets/image/gallery8.avif";
import gallery9 from "../assets/image/gallery9.jpeg";
import gallery10 from "../assets/image/gallery10.webp";
const images = [
    {id:1,img:gallery2},
    {id:2,img:gallery3},
    {id:3,img:gallery4},
    {id:4,img:gallery5},
    {id:5,img:gallery6},
    {id:6,img:gallery7},
    {id:7,img:gallery8},
    {id:0,img:gallery1},
    {id:8,img:gallery9},
    {id:9,img:gallery10},
  ]

export default images