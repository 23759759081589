import React, { useContext, useState } from "react";
import product from "../data/product";
import { NavLink, useParams } from "react-router-dom";
import slugify from "slugify";
import BreadCrumb from "./BreadCrumb";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import { Helmet } from "react-helmet";

const ProductDetails = () => {
  const [data] = useState(product);
  const [active, setActive] = useState("desc");
  const { productName } = useParams();
  const findData =
    product &&
    product.find((item) => slugify(item.title).toLowerCase() == productName);
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
        <Helmet>
      <title>{findData.title + " " + t.serie}</title>
    </Helmet>
      <BreadCrumb title={findData.title + " " + t.serie} />
      <section className="productDetails">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 mt-4">
              {data.map((item) => {
                const findActive = findData.id == item.id;
                return (
                    <NavLink
                      to={`/${slugify(item.title).toLowerCase()}`}
                      style={{
                        display: "block",
                      }}
                      className="navlink"
                    >
                  <div className={`mt-3 btnLink ${findActive ? "active" : ""}`}>
                      {item.title} {t.serie}
                  </div>
                    </NavLink>
                );
              })}
            </div>
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 my-5">
              <img src={findData.img} alt="" className="img-fluid" loading="lazy"/>
            </div>
            <div className="col-11 col-sm-10 col-md-10 col-lg-10">
              <div className="desc">
                <h2 className="text-center my-5">
                  {findData.title + " " + t.serie}
                </h2>
                <h3 className="mb-3">{t.desc} :</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      language == "az"
                        ? findData.desc
                        : language == "en"
                        ? findData.desc_en
                        : language == "tr"
                        ? findData.desc_tr
                        : language == "ru"
                        ? findData.desc_ru
                        : findData.desc_de,
                  }}
                  className="dangerLi"
                />
                <h3 className="my-5">{t.charac} :</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      language == "az"
                        ? findData.table
                        : language == "en"
                        ? findData.table_en
                        : language == "tr"
                        ? findData.table_tr
                        : language == "ru"
                        ? findData.table_ru
                        : findData.table_de,
                  }}
                  className="dangerLi"
                  style={{ paddingBottom: "70px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductDetails;
