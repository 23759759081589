import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import { NavLink } from "react-router-dom";
import blog from "../data/blog";
import BreadCrumb from "./BreadCrumb";
import slugify from "slugify";
import { Helmet } from "react-helmet";
const Blog = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <Helmet>
      <title>{t.blog}</title>
    </Helmet>
      <BreadCrumb title={t.blog} />
      <section className="blog">
        <section className="project__section section--padding">
          <div className="container">
            <div className="row mb--n40">
              {blog.map((item, index) => {
                return (
                  <div className="col-lg-6 col-md-6 mb-40">
                    <NavLink to={`/blog/${slugify(item.title_az).toLowerCase()}`}>
                      <div className="project__items">
                        <div className="project__thumbnail">
                          <a className="project__thumbnail--link" href="#">
                            <img
                              className="project__thumbnail--media"
                              src={item.img}
                              alt="project-img"
                              loading="lazy"
                            />
                          </a>
                        </div>
                        <div
                          className="project__content"
                          style={{ marginLeft: "0", marginRight: "0" }}
                        >
                          <h3 className="project__content--title">
                            <div className="d-flex justify-content-between">
                              <a>{language === "az" ? item.title_az : language === "en" ? item.title_en : language === "tr" ? item.title_tr : language === "de" ? item.title_de : item.title_ru}</a>
                              <span
                                style={{ fontSize: "small" }}
                                className="text-secondary ms-2"
                              >
                                {item.date}
                              </span>
                            </div>
                          </h3>
                          <p>
                            {language === "az" ? item.desc_az.slice(0,145) : language === "en" ? item.desc_en.slice(0,145) : language === "tr" ? item.desc_tr.slice(0,145) : language === "de" ? item.desc_de.slice(0,145) : item.desc_ru.slice(0,145)}...
                          </p>
                          <a className="project__content--link">
                            {t.readMore}{" "}
                            <svg
                              width={17}
                              height={7}
                              viewBox="0 0 17 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.9227 3.10264L12.6345 0.163758C12.5807 0.112601 12.5128 0.071331 12.4358 0.0430016C12.3588 0.0146722 12.2745 0 12.1892 0C12.1039 0 12.0197 0.0146722 11.9426 0.0430016C11.8656 0.071331 11.7977 0.112601 11.7439 0.163758C11.625 0.276582 11.5601 0.417625 11.5601 0.563112C11.5601 0.708598 11.625 0.849607 11.7439 0.962432L13.9509 2.93537H0.62744C0.452746 2.95613 0.293442 3.0245 0.178355 3.12813C0.0632677 3.23176 0 3.3638 0 3.50034C0 3.63688 0.0632677 3.76888 0.178355 3.87251C0.293442 3.97614 0.452746 4.04455 0.62744 4.06531H13.9538L11.7468 6.03821C11.6277 6.15095 11.5626 6.29202 11.5626 6.43756C11.5626 6.58311 11.6277 6.72418 11.7468 6.83692C11.8006 6.88788 11.8684 6.92896 11.9453 6.95717C12.0222 6.98537 12.1062 7 12.1914 7C12.2765 7 12.3605 6.98537 12.4374 6.95717C12.5143 6.92896 12.5821 6.88788 12.6359 6.83692L15.9241 3.89804C16.0417 3.78535 16.1057 3.64489 16.1055 3.50014C16.1052 3.35538 16.0407 3.21508 15.9227 3.10264Z"
                                fill="currentColor"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Blog;
