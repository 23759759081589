import React, { useContext } from 'react'
import banner from "../assets/image/breadcrumb.png"
import { LanguageContext } from '../context/LanguageContext';
import translations from '../data/langData';
const BreadCrumb = ({title}) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
  
    <div className="breadcrumb">
        <img src={banner} alt="" className="img-fluid"  loading="lazy"/>
        <div className="outline d-flex justify-content-center align-items-center">
           <h2 className='text-center'>{title}
            <p className='text-center' style={{fontSize:"small"}}>{t.home} / {title} </p>
           </h2>
        </div>
    </div>
    </>
  )
}

export default BreadCrumb
