const faq = [
    {
        id: 0,
        title_az: "PVC pəncərələri nədir?",
        desc_az: "<ul><li>PVC pəncərələri, polivinilxlorid (PVC) materialından hazırlanan pəncərə sistemləridir. Bu material yüksək izolyasiya xüsusiyyətlərinə malikdir və uzun müddət dayanıqlıdır.</li></ul>",
        title_tr: "PVC pencereleri nedir?",
        desc_tr: "<ul><li>PVC pencereleri, polivinilklorür (PVC) malzemesinden üretilen pencere sistemleridir. Bu malzeme yüksek izolasyon özelliklerine sahiptir ve uzun süre dayanıklıdır.</li></ul>",
        title_ru: "Что такое PVC окна?",
        desc_ru: "<ul><li>ПВХ окна – это оконные системы, изготовленные из поливинилхлорида (ПВХ). Этот материал обладает высокими изоляционными свойствами и долговечен.</li></ul>",
        title_en: "What are PVC windows?",
        desc_en: "<ul><li>PVC windows are window systems made from polyvinyl chloride (PVC) material. This material has high insulation properties and is durable for a long time.</li></ul>",
        title_de: "Was sind PVC-Fenster?",
        desc_de: "<ul><li>PVC-Fenster sind Fenstersysteme, die aus Polyvinylchlorid (PVC) hergestellt sind. Dieses Material hat hervorragende Isoliereigenschaften und ist langlebig.</li></ul>"
    },
    {
        id: 1,
        title_az: "PVC pəncərələrinin üstünlükləri nələrdir?",
        desc_az: "<ul><li>İzolyasiya: Yüksək istilik və səs izolyasiyası təmin edir.</li> </br> <li>Rəng və dizayn seçimləri: Müxtəlif rənglərdə və dizaynlarda istehsal olunur.</li> </br> <li>Quraşdırma asanlığı: Asanlıqla quraşdırılır və baxımı azdır.</li> </br> <li>Uzun ömür: Korroziya və zərbələrə qarşı dayanıqlıdır.</li></ul>",
        title_tr: "PVC pencerelerinin avantajları nelerdir?",
        desc_tr: "<ul><li>İzolasyon: Yüksek ısı ve ses izolasyonu sağlar.</li> </br> <li>Renk ve tasarım seçenekleri: Farklı renk ve tasarımlarda üretilir.</li> </br> <li>Kurulum kolaylığı: Kolayca kurulur ve bakımı azdır.</li> </br> <li>Uzun ömür: Korozyona ve darbelere karşı dayanıklıdır.</li></ul>",
        title_ru: "Преимущества ПВХ окон?",
        desc_ru: "<ul><li>Изоляция: Обеспечивает высокую тепло- и звукоизоляцию.</li> </br> <li>Цвет и дизайн: Изготавливаются в различных цветах и дизайнах.</li> </br> <li>Легкость установки: Легко устанавливаются и требуют минимального обслуживания.</li> </br> <li>Долговечность: Устойчивы к коррозии и ударам.</li></ul>",
        title_en: "What are the advantages of PVC windows?",
        desc_en: "<ul><li>Insulation: Provides high thermal and sound insulation.</li> </br> <li>Color and design options: Produced in various colors and designs.</li> </br> <li>Ease of installation: Easily installed and requires little maintenance.</li> </br> <li>Durability: Resistant to corrosion and impacts.</li></ul>",
        title_de: "Was sind die Vorteile von PVC-Fenstern?",
        desc_de: "<ul><li>Isolierung: Bietet hohe Wärme- und Schallisolation.</li> </br> <li>Farben- und Designoptionen: Erhältlich in verschiedenen Farben und Designs.</li> </br> <li>Einfachheit der Installation: Lässt sich leicht installieren und benötigt wenig Wartung.</li> </br> <li>Lebensdauer: Widerstandsfähig gegen Korrosion und Stöße.</li></ul>"
    },
    {
        id: 2,
        title_az: "PVC pəncərələri necə baxılmalıdır?",
        desc_az: "<ul><li>Təmizlik: Nəm bir parça ilə silinməsi kifayətdir.</li> </br> <li>Mexanizmlərin yoxlanması: Qapı və pəncərə mexanizmlərinin düzgün işlədiyinə əmin olun.</li></ul>",
        title_tr: "PVC pencerelere nasıl bakım yapılır?",
        desc_tr: "<ul><li>Temizlik: Nemli bir bezle silinmesi yeterlidir.</li> </br> <li>Mekanizm kontrolü: Kapı ve pencere mekanizmalarının düzgün çalıştığından emin olun.</li></ul>",
        title_ru: "Как ухаживать за ПВХ окнами?",
        desc_ru: "<ul><li>Уборка: Достаточно протереть влажной тряпкой.</li> </br> <li>Проверка механизмов: Убедитесь, что механизмы дверей и окон работают правильно.</li></ul>",
        title_en: "How to maintain PVC windows?",
        desc_en: "<ul><li>Cleaning: Wiping with a damp cloth is sufficient.</li> </br> <li>Checking mechanisms: Ensure that door and window mechanisms operate correctly.</li></ul>",
        title_de: "Wie pflegt man PVC-Fenster?",
        desc_de: "<ul><li>Reinigung: Es reicht aus, mit einem feuchten Tuch abzuwischen.</li> </br> <li>Überprüfung der Mechanismen: Stellen Sie sicher, dass die Tür- und Fenstermechanismen richtig funktionieren.</li></ul>"
    },
    {
        id: 3,
        title_az: "PVC pəncərələrinin qiyməti nə ilə müəyyən olunur?",
        desc_az: "<ul><li>Ölçülər: Pəncərənin ölçüsü qiymətə təsir edir.</li> </br> <li>Dizayn: İstədiyiniz dizayn və funksiyalar (məsələn, açılma növü) qiyməti dəyişə bilər.</li> </br> </ul>",
        title_tr: "PVC pencerelerinin fiyatı neye göre belirlenir?",
        desc_tr: "<ul><li>Boyutlar: Pencerenin boyutu fiyatı etkiler.</li> </br> <li>Tasarım: İstediğiniz tasarım ve fonksiyonlar (örneğin, açılma türü) fiyatı değiştirebilir.</li> </br> </ul>",
        title_ru: "Что определяет цену ПВХ окон?",
        desc_ru: "<ul><li>Размеры: Размер окна влияет на цену.</li> </br> <li>Дизайн: Желаемый дизайн и функции (например, тип открытия) могут изменить цену.</li> </br> </ul>",
        title_en: "What determines the price of PVC windows?",
        desc_en: "<ul><li>Sizes: The size of the window affects the price.</li> </br> <li>Design: Desired design and features (e.g., type of opening) can change the price.</li> </br> </ul>",
        title_de: "Was bestimmt den Preis von PVC-Fenstern?",
        desc_de: "<ul><li>Größen: Die Größe des Fensters beeinflusst den Preis.</li> </br> <li>Design: Gewünschtes Design und Funktionen (z.B. Öffnungsart) können den Preis ändern.</li> </br> </ul>"
    },
    {
        id: 4,
        title_az: "PVC pəncərələrinin təyinatı nələrdır?",
        desc_az: "<ul><li>Ev pəncərələri: Müstəqil evlərdə geniş istifadə olunur.</li> </br> <li>Ticarət binaları: Ofis və ticarət məkanlarında da yaygındır.</li> </br> </ul>",
        title_tr: "PVC pencerelerin kullanımı nedir?",
        desc_tr: "<ul><li>Ev pencereleri: Bağımsız evlerde yaygın olarak kullanılır.</li> </br> <li>Ticari binalar: Ofis ve ticaret alanlarında da yaygındır.</li> </br> </ul>",
        title_ru: "Назначение ПВХ окон?",
        desc_ru: "<ul><li>Окон для домов: Широко используются в частных домах.</li> </br> <li>Коммерческие здания: Также распространены в офисах и коммерческих помещениях.</li> </br> </ul>",
        title_en: "What is the purpose of PVC windows?",
        desc_en: "<ul><li>Residential windows: Widely used in independent houses.</li> </br> <li>Commercial buildings: Also common in offices and commercial spaces.</li> </br> </ul>",
        title_de: "Wofür werden PVC-Fenster verwendet?",
        desc_de: "<ul><li>Wohnfenster: Weit verbreitet in Einfamilienhäusern.</li> </br> <li>Gewerbliche Gebäude: Auch in Büros und Geschäftsräumen verbreitet.</li> </br> </ul>"
    },
    {
        id: 5,
        title_az: "PVC pəncərələrinin ömrü nə qədərdir?",
        desc_az: "<ul><li>PVC pəncərələrinin ömrü adətən 50 ildən artıq ola bilər, yəni bir insan ömrü qədər.</li></ul>",
        title_tr: "PVC pencerelerinin ömrü ne kadardır?",
        desc_tr: "<ul><li>PVC pencerelerin ömrü genellikle 50 yıldan fazla olabilir, yani bir insan ömrü kadar.</li></ul>",
        title_ru: "Срок службы ПВХ окон?",
        desc_ru: "<ul><li>Срок службы ПВХ окон обычно может превышать 50 лет, то есть равен человеческой жизни.</li></ul>",
        title_en: "What is the lifespan of PVC windows?",
        desc_en: "<ul><li>The lifespan of PVC windows can generally exceed 50 years, which is about a human lifespan.</li></ul>",
        title_de: "Wie lange halten PVC-Fenster?",
        desc_de: "<ul><li>Die Lebensdauer von PVC-Fenstern kann in der Regel über 50 Jahre betragen, was der Lebensdauer eines Menschen entspricht.</li></ul>"
    },
];

export default faq;
