import React, { useContext } from 'react'
import Slider from '../../pages/HomePages/Slider'
import Categories from '../../pages/HomePages/Categories'
import HomeProduct from '../../pages/HomePages/HomeProduct'
import { Helmet } from 'react-helmet'
import { LanguageContext } from '../../context/LanguageContext'
import translations from '../../data/langData'

const Home = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
    <Helmet>
      <title>{"Scheffer Azerbaijan | Plastik qapı pəncərə "}</title>
    </Helmet>
    <Slider/>
    <HomeProduct/>
    <Categories/>
    </>
  )
}

export default Home
