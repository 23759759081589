import React, { useContext, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectFade, Navigation, Pagination } from "swiper/modules";
import { NavLink } from "react-router-dom";
import slider1 from "../../assets/image/slider1.JPG";
import slider2 from "../../assets/image/banner.jpg";
import translations from "../../data/langData";
import { LanguageContext } from "../../context/LanguageContext";
import video from "../../assets/image/2.mp4";
import video1 from "../../assets/image/1.mp4";
const Slider = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const videoRef = useRef(null);
  const videoRef1 = useRef(null);
  const [isMuted, setIsMuted] = useState(true); // Başlangıçta ses açık
  const [isMuted1, setIsMuted1] = useState(true); // Başlangıçta ses açık

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted; // Ses durumunu güncelle
      videoRef.current.play().catch((error) => {
        console.error("Video oynatılamadı:", error);
      });
    }
    if (videoRef1.current) {
      videoRef1.current.muted = isMuted1; // Ses durumunu güncelle
      videoRef1.current.play().catch((error) => {
        console.error("Video oynatılamadı:", error);
      });
    }
  }, [isMuted,isMuted1]);

  const handleMuteToggle = () => {
    setIsMuted((prev) => !prev); // Ses durumunu değiştir
  };
  const handleMuteToggle1 = () => {
    setIsMuted1((prev) => !prev); // Ses durumunu değiştir
  };
  return (
    <>
      <section className="slider pb-5">
        <div className="container-fluid">
          <div className="row" id="sliderRow">
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6"
              style={{ background: "#f9f9f9" }}
            >
              <div className="leftSection ms-5">
                <p
                  style={{
                    fontSize: "small",
                    color: "rgb(7, 22, 137)",
                    fontWeight: "bold",
                  }}
                  className="d-flex justify-content-start align-items-center"
                >
                  {/* <svg
                    width={14}
                    height={14}
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_15_6)">
                      <path
                        d="M9.00021 4.72925L2.5806 10.0215C2.5806 10.029 2.57872 10.04 2.57497 10.055C2.57129 10.0698 2.56934 10.0806 2.56934 10.0883V15.4473C2.56934 15.6408 2.64008 15.8085 2.78152 15.9497C2.92292 16.091 3.09037 16.1621 3.2839 16.1621H7.571V11.8747H10.4295V16.1622H14.7165C14.91 16.1622 15.0777 16.0913 15.2189 15.9497C15.3603 15.8086 15.4313 15.6408 15.4313 15.4473V10.0883C15.4313 10.0586 15.4272 10.0361 15.4201 10.0215L9.00021 4.72925Z"
                        fill="#F23B3B"
                      />
                      <path
                        d="M17.8758 8.81572L15.4309 6.78374V2.2285C15.4309 2.12437 15.3974 2.03872 15.3302 1.9717C15.2636 1.90475 15.178 1.87128 15.0736 1.87128H12.93C12.8258 1.87128 12.7401 1.90475 12.6731 1.9717C12.6062 2.03872 12.5727 2.1244 12.5727 2.2285V4.4056L9.8486 2.12792C9.61069 1.93439 9.3278 1.83765 9.00026 1.83765C8.67275 1.83765 8.3899 1.93439 8.15175 2.12792L0.124063 8.81572C0.0496462 8.87516 0.00885955 8.95517 0.00127316 9.05567C-0.00627412 9.15609 0.0197308 9.2438 0.079366 9.31818L0.771565 10.1444C0.831201 10.2113 0.909254 10.2523 1.00604 10.2673C1.09539 10.2748 1.18475 10.2486 1.27411 10.1891L9.00002 3.74687L16.726 10.1891C16.7857 10.241 16.8637 10.2669 16.9605 10.2669H16.994C17.0907 10.2522 17.1686 10.211 17.2285 10.1442L17.9208 9.31814C17.9803 9.2436 18.0064 9.15605 17.9987 9.05551C17.991 8.95528 17.9501 8.87527 17.8758 8.81572Z"
                        fill="#F23B3B"
                      />
                    </g>
                    <defs>
                      <clipPath>
                        <rect width={18} height={18} fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{" "} */}
                  {t.sliderTitle2.toUpperCase()}
                </p>
                <h2 style={{ fontWeight: "800" }} className="sliderTitle">
                  {t.sliderTitle1.toUpperCase()}
                </h2>
                <h3
                  style={{ fontWeight: "400", fontSize: "30px" }}
                  className="my-3"
                >
                  {t.sliderTitle}
                </h3>
                <div className="main__header--right d-flex align-items-center mt-5">
                  <NavLink to="/calc" className="add__listing--btn solid__btn">
                    <span>{t.order}</span>{" "}
                  </NavLink>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6"
              style={{ padding: "0" }}
            >
              <Swiper
                spaceBetween={30}
                effect={"fade"}
                pagination={{ clickable: true }} // Pagination-i aktivləşdir
                autoplay={{
                  delay: 500,
                  disableOnInteraction: false,
                }}
                modules={[EffectFade, Pagination]} // Pagination modülünü əlavə et
                className="homeSlider"
              >
                <SwiperSlide>
                <video
                    autoPlay
                    ref={videoRef}
                    loop
                    muted={isMuted} 
                    style={{ width: "100%", height: "auto" }}
                  >
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <button id={`${isMuted ? "volumeOff" : "volumeOn"}`} className="videoBtn" onClick={handleMuteToggle} style={{ position: "absolute", top: "10px", right: "20px" }}>
                  </button>
                </SwiperSlide>
                <SwiperSlide>
                  <video
                    autoPlay
                    ref={videoRef1}
                    loop
                    muted={isMuted1} 
                    style={{ width: "100%", height: "auto" }}
                  >
                    <source src={video1} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <button id={`${isMuted1 ? "volumeOff" : "volumeOn"}`} className="videoBtn" onClick={handleMuteToggle1} style={{ position: "absolute", top: "10px", right: "20px" }}>
                  </button>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      {/* <Swiper
        spaceBetween={30}
        effect={"fade"}
        navigation={false}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        modules={[EffectFade, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide className="mb-5">
          <div className="slider_container">
            <div className="slider_text">
            <h2>{t.homeslider}</h2>
            <p>
            {t.homeslider1}
            </p>
            </div>

            <img src={slider1} className="img-fluid homeBanner" loading="lazy" />
            <div className="outline"></div>
          </div>
        </SwiperSlide>
      </Swiper> */}
    </>
  );
};

export default Slider;
