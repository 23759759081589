import React, { useContext, useEffect, useState } from "react";
import BreadCrumb from "./BreadCrumb";
import tek from "../assets/image/tek.jpg";
import icon1 from "../assets/image/icon1.jpg";
import icon2 from "../assets/image/icon2.jpg";
import icon3 from "../assets/image/icon3.jpg";
import icon4 from "../assets/image/icon4.jpg";
import icon5 from "../assets/image/icon5.jpg";
import icon6 from "../assets/image/icon6.jpg";
import icon7 from "../assets/image/icon7.jpg";
import icon8 from "../assets/image/icon8.jpg";
import icon9 from "../assets/image/icon9.jpg";
import icon10 from "../assets/image/icon10.jpg";
import icon11 from "../assets/image/icon11.jpg";
import icon12 from "../assets/image/icon12.jpg";
import icon13 from "../assets/image/icon13.jpg";
import icon14 from "../assets/image/icon14.jpg";
import icon15 from "../assets/image/icon15.png";

import color from "../assets/image/color.png";
import color1 from "../assets/image/color1.png";
import color2 from "../assets/image/color2.png";
import color3 from "../assets/image/color3.png";
import color4 from "../assets/image/color4.png";

import window1 from "../assets/image/window1.jpg";
import window2 from "../assets/image/window2.jpg";
import window3 from "../assets/image/window3.jpg";
import window4 from "../assets/image/window4.jpg";
import window5 from "../assets/image/window5.jpg";
import window6 from "../assets/image/window6.jpg";
import window7 from "../assets/image/window7.jpg";
import window8 from "../assets/image/window8.jpg";
import window9 from "../assets/image/window9.jpg";
import window10 from "../assets/image/window10.jpg";
import window11 from "../assets/image/window11.jpg";
import window12 from "../assets/image/window12.jpg";
import window13 from "../assets/image/window13.jpg";
import window14 from "../assets/image/window14.jpg";
import window15 from "../assets/image/window15.jpg";

import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import Swal from "sweetalert2";
import axios from "axios";
const Calc = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const [iconSelect, setIconSelect] = useState("");
  const [colorSelect, setColorSelect] = useState("");

  const [width, setWidth] = useState("1300");
  const [height, setHeight] = useState("1460");

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [note, setNote] = useState("");
  const [packet, setPacket] = useState("");

  const [nameError, setNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [noteError, setNoteError] = useState(false);

  const [message, setMesage] = useState("");
  const [messageH, setMessageH] = useState("");

  useEffect(() => {
    if (width < 500 || width > 2700) {
      setMesage(t.invalid);
    } else {
      setMesage("");
    }
    if (height < 500 || height > 2000) {
      setMessageH(t.invalid1);
    } else {
      setMessageH("");
    }
  }, [width, height]);
  const icon = [
    {
      id: 0,
      icon: icon1,
    },
    {
      id: 1,
      icon: icon2,
    },
    {
      id: 2,
      icon: icon3,
    },
    {
      id: 3,
      icon: icon4,
    },
    {
      id: 4,
      icon: icon5,
    },
    {
      id: 5,
      icon: icon6,
    },
    {
      id: 6,
      icon: icon7,
    },
    {
      id: 7,
      icon: icon8,
    },
    {
      id: 8,
      icon: icon9,
    },
    {
      id: 9,
      icon: icon10,
    },
    {
      id: 10,
      icon: icon11,
    },
    {
      id: 11,
      icon: icon12,
    },
    {
      id: 12,
      icon: icon13,
    },
    {
      id: 13,
      icon: icon14,
    },
    {
      id: 14,
      icon: icon15,
    }
  ];
  const window = [
    {
      id: 0,
      window: window1,
    },
    {
      id: 1,
      window: window2,
    },
    {
      id: 2,
      window: window3,
    },
    {
      id: 3,
      window: window4,
    },
    {
      id: 4,
      window: window5,
    },
    {
      id: 5,
      window: window6,
    },
    {
      id: 6,
      window: window7,
    },
    {
      id: 7,
      window: window8,
    },
    {
      id: 8,
      window: window9,
    },
    {
      id: 9,
      window: window10,
    },
    {
      id: 10,
      window: window11,
    },
    {
      id: 11,
      window: window12,
    },
    {
      id: 12,
      window: window13,
    },
    {
      id: 13,
      window: window14,
    },
    {
      id: 14,
      window: window15,
    },
  ];
  const colorData = [
    {
      id: 0,
      color: color,
    },
    {
      id: 1,
      color: color2,
    },
    {
      id: 2,
      color: color4,
    },
    {
      id: 3,
      color: color3,
    },
    {
      id: 4,
      color: color1,
    },
  ];

  const handleIcon = (iconId) => {
    setIconSelect(iconId);
  };

  const handleColor = (colorId) => {
    setColorSelect(colorId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setNameError(name.trim() === "");
    setLastNameError(lastName.trim() === "");
    setPhoneError(phone.trim() === "");
    setNoteError(note.trim() === "");
    if (name.trim() && lastName.trim() && phone.trim() && note.trim()) {
      const formData = new FormData();

      formData.append("icon", iconSelect);
      formData.append("color", colorSelect);
      formData.append("packet", packet);
      formData.append("width", width);
      formData.append("height", height);
      formData.append("name", name);
      formData.append("lastName", lastName);
      formData.append("phone", phone);
      formData.append("note", note);

      axios
        .post("https://schefferpvc.az/send.php", formData)
        .then((res) => {
          if (res.data == "ok" && res.status === 200) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: t.success,
              showConfirmButton: false,
              timer: 1500,
            });
            setName("");
            setLastName("");
            setPhone("");
            setNote("");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <BreadCrumb title={t.order} />
      <section className="calc" style={{ paddingBottom: "100px" }}>
        <div className="container-fluid mt-5">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-12 ">
              <div style={{ position: "sticky", top: 150 }}>
                <div className="d-flex  align-items-center heightDiv">
                  <div className="height">
                    <div className="heightText">
                      {messageH.length > 0 ? "" : height} MM
                    </div>
                  </div>
                  {window
                    .filter((i) => i.id == iconSelect)
                    .map((item) => {
                      return (
                        <div className="windowFixed">
                          <img
                            src={item.window}
                            alt=""
                            className="img-fluid w-100"
                          />
                        </div>
                      );
                    })}
                </div>
                <div className="width">
                  <div className="text">
                    {message.length > 0 ? "" : width} <span>MM</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-12 ">
              <form action="" onSubmit={handleSubmit}>
                <div className="calcInput">
                  <h2>{t.windowSetting}</h2>
                  <div className="row">
                    {icon.map((item) => {
                      return (
                        <div className="col-lg-2 col-3">
                          <div
                            className={`iconBox d-flex justify-content-center my-3 ${
                              iconSelect == item.id ? "iconActive" : ""
                            }`}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleIcon(item.id)}
                          >
                            <img src={item.icon} alt="" className="img-fluid" 
                            
                            />
                          </div>
                        </div>
                      );
                    })}
                    <h3 className="my-3">{t.color}</h3>
                    {colorData.map((item) => {
                      return (
                        <div className="col-lg-2 col-6">
                          <div
                            className="colorBox"
                            onClick={() => handleColor(item.id)}
                          >
                            <img
                              src={item.color}
                              alt=""
                              id="colorImg"
                              className={`img-fluid m-2 ${
                                colorSelect == item.id ? "colorActive" : ""
                              }`}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <div className="col-12 mt-5">
                      <p style={{ fontWeight: "bold" }}>{t.packet}</p>
                      <select
                        name=""
                        id=""
                        className="py-3"
                        style={{ width: "100%" }}
                        onChange={(e) => setPacket(e.target.value)}
                      >
                        <option value="Seçilməyib" selected>
                          {t.option}
                        </option>
                        <option value="Tək qat şüşə paketi">{t.option1}</option>
                        <option value="İki qat şüşə paketi">{t.option2}</option>
                        <option value="Üç qat şüşə paketi">{t.option3}</option>
                        <option value="Dekorativ şəbəkələr">{t.option4}</option>
                        <option value="Enerji-qənaət şüşə paketi">
                          {t.option5}
                        </option>
                        <option value="Rəngli şüşələr">{t.option6}</option>
                      </select>
                    </div>
                    <div className="col-6 my-3">
                      <p style={{ fontWeight: "bold" }}>{t.width}</p>
                      <input
                        type="text"
                        className="py-3 w-100"
                        defaultValue={width}
                        onChange={(e) => setWidth(e.target.value)}
                      />
                      {message.length > 0 && (
                        <span
                          className="text-danger fs-4 mt-1"
                          style={{ lineHeight: "normal" }}
                        >
                          {message}
                        </span>
                      )}
                    </div>
                    <div className="col-6 my-3">
                      <p style={{ fontWeight: "bold" }}>{t.height}</p>
                      <input
                        type="text"
                        className="py-3 w-100"
                        defaultValue={height}
                        onChange={(e) => setHeight(e.target.value)}
                      />
                      {messageH.length > 0 && (
                        <span
                          className="text-danger fs-4 mt-1"
                          style={{ lineHeight: "normal" }}
                        >
                          {messageH}
                        </span>
                      )}
                    </div>
                    <div className="col-6 my-3">
                      <label htmlFor="" style={{ fontWeight: "bold" }}>
                        {t.name}
                      </label>
                      <input
                        type="text"
                        className="py-3 w-100 "
                        placeholder={t.name}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setNameError(false);
                        }}
                        style={{
                          border: `${nameError ? "1px solid red" : ""}`,
                        }}
                      />
                      {nameError && (
                        <span
                          className="text-danger fs-4 mt-1"
                          style={{ lineHeight: "normal" }}
                        >
                          {t.nameErr}
                        </span>
                      )}
                    </div>
                    <div className="col-6 my-3">
                      <label htmlFor="" style={{ fontWeight: "bold" }}>
                        {t.lastName}
                      </label>
                      <input
                        type="text"
                        className="py-3 w-100"
                        placeholder={t.lastName}
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                          setLastNameError(false);
                        }}
                        style={{
                          border: `${lastNameError ? "1px solid red" : ""}`,
                        }}
                      />
                      {lastNameError && (
                        <span
                          className="text-danger fs-4 mt-1"
                          style={{ lineHeight: "normal" }}
                        >
                          {t.lastNameErr}
                        </span>
                      )}
                    </div>
                    <div className="col-12">
                      <label htmlFor="" style={{ fontWeight: "bold" }}>
                        {t.phone}
                      </label>
                      <input
                        type="text"
                        className="py-3 w-100"
                        placeholder={t.phone}
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                          setPhoneError(false);
                        }}
                        style={{
                          border: `${phoneError ? "1px solid red" : ""}`,
                        }}
                      />
                      {phoneError && (
                        <span
                          className="text-danger fs-4 mt-1"
                          style={{ lineHeight: "normal" }}
                        >
                          {t.phoneErr}
                        </span>
                      )}
                    </div>
                    <div className="col-12 my-3">
                      <label htmlFor="" style={{ fontWeight: "bold" }}>
                        {t.note} :
                      </label>
                      <textarea
                        name=""
                        id=""
                        style={{
                          width: "100%",
                          border: `${noteError ? "1px solid red" : ""}`,
                        }}
                        onChange={(e) => {
                          setNote(e.target.value);
                          setNoteError(false);
                        }}
                        value={note}
                      ></textarea>
                      {noteError && (
                        <span
                          className="text-danger fs-4"
                          style={{ lineHeight: "normal" }}
                        >
                          {t.noteErr}
                        </span>
                      )}
                    </div>
                    <div className="col-12 mt-5">
                      <button type="submit">{t.lastOrder}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Calc;
