import React, { useContext } from "react";
import BreadCrumb from "./BreadCrumb";
import faq from "../data/faq";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
const Faq = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <BreadCrumb title={t.faq} />
      <section className="faq py-5">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-sm-12 col-12">
              <div className="accordion" id="accordionExample">
                {faq.map((item, index) => {
                  const isOpen = item.id === 0; // İlk elementi açıq etmək üçün yoxlama
                  return (
                    <div className="accordion-item p-3" key={item.id}>
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button fs-3"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index}`}
                          aria-expanded={isOpen} // İlk element açıqdırsa true, yoxsa false
                          aria-controls={`collapse${index}`}
                        >
                          {language === "az" ? item.title_az : language === "tr" ? item.title_tr : language === "en" ? item.title_en : language === "de" ? item.title_de : item.title_ru}
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className={`accordion-collapse collapse ${
                          isOpen ? "show" : ""
                        }`} // İlk elementi açıq edirik
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body ms-5"
                          dangerouslySetInnerHTML={{ __html: language === "az" ? item.desc_az : language === "tr" ? item.desc_tr : language === "en" ? item.desc_en : language === "de" ? item.desc_de : item.desc_ru }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
