import React, { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import blog from "../../data/blog";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import slugify from "slugify";
import { NavLink } from "react-router-dom";
const HomeBlog = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
          <h3 className="section__heading--subtitle h5 text-center">
        <svg
          width={18}
          height={18}
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_15_6)">
            <path
              d="M9.00021 4.72925L2.5806 10.0215C2.5806 10.029 2.57872 10.04 2.57497 10.055C2.57129 10.0698 2.56934 10.0806 2.56934 10.0883V15.4473C2.56934 15.6408 2.64008 15.8085 2.78152 15.9497C2.92292 16.091 3.09037 16.1621 3.2839 16.1621H7.571V11.8747H10.4295V16.1622H14.7165C14.91 16.1622 15.0777 16.0913 15.2189 15.9497C15.3603 15.8086 15.4313 15.6408 15.4313 15.4473V10.0883C15.4313 10.0586 15.4272 10.0361 15.4201 10.0215L9.00021 4.72925Z"
              fill="#F23B3B"
            />
            <path
              d="M17.8758 8.81572L15.4309 6.78374V2.2285C15.4309 2.12437 15.3974 2.03872 15.3302 1.9717C15.2636 1.90475 15.178 1.87128 15.0736 1.87128H12.93C12.8258 1.87128 12.7401 1.90475 12.6731 1.9717C12.6062 2.03872 12.5727 2.1244 12.5727 2.2285V4.4056L9.8486 2.12792C9.61069 1.93439 9.3278 1.83765 9.00026 1.83765C8.67275 1.83765 8.3899 1.93439 8.15175 2.12792L0.124063 8.81572C0.0496462 8.87516 0.00885955 8.95517 0.00127316 9.05567C-0.00627412 9.15609 0.0197308 9.2438 0.079366 9.31818L0.771565 10.1444C0.831201 10.2113 0.909254 10.2523 1.00604 10.2673C1.09539 10.2748 1.18475 10.2486 1.27411 10.1891L9.00002 3.74687L16.726 10.1891C16.7857 10.241 16.8637 10.2669 16.9605 10.2669H16.994C17.0907 10.2522 17.1686 10.211 17.2285 10.1442L17.9208 9.31814C17.9803 9.2436 18.0064 9.15605 17.9987 9.05551C17.991 8.95528 17.9501 8.87527 17.8758 8.81572Z"
              fill="#F23B3B"
            />
          </g>
          <defs>
            <clipPath>
              <rect width={18} height={18} fill="white" />
            </clipPath>
          </defs>
        </svg>
        {t.kunstoff}
      </h3>
      <h2 className="section__heading--title text-center">{t.blog}</h2>
      <section className="project__section my-5">
        <div className="container">
          <div className="row mb--n40 justify-content-center">
            <Swiper
              slidesPerView={1}
              spaceBetween={3}
              navigation={true}
              loop={true}
              autoplay={{
                // Enable autoplay
                delay: 1500, // Delay between slides (in milliseconds)
                disableOnInteraction: false, // Keep autoplay running after interaction
              }}
              speed={2000}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 33,
                },
              }}
              modules={[Navigation, Autoplay]}
              className="mySwiper"
            >
              {blog.map((item) => (
                <div className="col-lg-4 col-md-6 col-12 mb-40 ">
                  <SwiperSlide>
                    <NavLink
                      to={`/blog/${slugify(item.title_az).toLowerCase()}`}
                    >
                      <div className="project__items py-3">
                        <div className="project__thumbnail">
                          <a className="project__thumbnail--link">
                            <img
                              className="project__thumbnail--media"
                              src={item.img}
                              alt="project-img"
                              loading="lazy"
                            />
                          </a>
                        </div>
                        <div
                          className="project__content"
                          style={{ marginLeft: "0", marginRight: "0" }}
                        >
                          <h3 className="project__content--title">
                            <a>
                              {language === "az"
                                ? item.title_az
                                : language === "en"
                                ? item.title_en
                                : language === "tr"
                                ? item.title_tr
                                : language === "de"
                                ? item.title_de
                                : item.title_ru}{" "}
                              {t.serie}
                            </a>
                          </h3>
                          <a className="project__content--link">
                            {t.readMore}{" "}
                            <svg
                              width={17}
                              height={7}
                              viewBox="0 0 17 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.9227 3.10264L12.6345 0.163758C12.5807 0.112601 12.5128 0.071331 12.4358 0.0430016C12.3588 0.0146722 12.2745 0 12.1892 0C12.1039 0 12.0197 0.0146722 11.9426 0.0430016C11.8656 0.071331 11.7977 0.112601 11.7439 0.163758C11.625 0.276582 11.5601 0.417625 11.5601 0.563112C11.5601 0.708598 11.625 0.849607 11.7439 0.962432L13.9509 2.93537H0.62744C0.452746 2.95613 0.293442 3.0245 0.178355 3.12813C0.0632677 3.23176 0 3.3638 0 3.50034C0 3.63688 0.0632677 3.76888 0.178355 3.87251C0.293442 3.97614 0.452746 4.04455 0.62744 4.06531H13.9538L11.7468 6.03821C11.6277 6.15095 11.5626 6.29202 11.5626 6.43756C11.5626 6.58311 11.6277 6.72418 11.7468 6.83692C11.8006 6.88788 11.8684 6.92896 11.9453 6.95717C12.0222 6.98537 12.1062 7 12.1914 7C12.2765 7 12.3605 6.98537 12.4374 6.95717C12.5143 6.92896 12.5821 6.88788 12.6359 6.83692L15.9241 3.89804C16.0417 3.78535 16.1057 3.64489 16.1055 3.50014C16.1052 3.35538 16.0407 3.21508 15.9227 3.10264Z"
                                fill="currentColor"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </NavLink>
                  </SwiperSlide>
                </div>
              ))}
            </Swiper>
            <div className="col-4  d-flex justify-content-center my-5">
              <NavLink to="/blog" className="add__listing--btn solid__btn">
                <span>{t.readMore}</span>{" "}
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeBlog;
